import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1279.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
           
<path d="M5205 10444 c-629 -27 -1188 -138 -1755 -350 -63 -23 -155 -60 -205
-81 -49 -22 -110 -48 -135 -58 -79 -34 -381 -190 -500 -259 -222 -130 -529
-344 -710 -495 -340 -285 -653 -614 -880 -926 -24 -33 -46 -62 -50 -65 -10 -8
-213 -319 -252 -385 -152 -259 -249 -457 -360 -735 -75 -186 -177 -504 -208
-650 -4 -19 -19 -87 -34 -150 -80 -349 -124 -821 -112 -1205 29 -857 250
-1645 671 -2384 145 -254 368 -574 526 -756 14 -16 61 -70 104 -120 107 -123
335 -348 480 -475 874 -764 1953 -1219 3140 -1326 384 -34 997 -13 1335 46
226 40 311 58 500 105 858 214 1678 650 2318 1234 275 251 474 469 686 751 47
63 89 120 93 125 11 14 111 167 155 235 383 601 645 1327 738 2047 32 248 40
379 40 653 0 601 -89 1130 -283 1685 -214 609 -580 1233 -998 1701 -93 104
-318 332 -422 427 -299 274 -704 562 -1042 742 -49 26 -112 60 -140 75 -27 15
-102 51 -165 80 -614 282 -1246 448 -1905 499 -147 12 -509 20 -630 15z m662
-738 c1037 -110 1980 -550 2707 -1264 744 -730 1194 -1660 1302 -2691 21 -192
23 -641 5 -826 -66 -681 -279 -1323 -626 -1885 -87 -141 -198 -303 -277 -401
-29 -36 -69 -86 -88 -110 -115 -143 -375 -408 -535 -544 -243 -207 -531 -404
-800 -546 -541 -287 -1066 -447 -1695 -516 -212 -23 -703 -23 -915 1 -628 71
-1221 255 -1720 533 -128 72 -344 205 -415 257 -273 199 -393 300 -591 496
-604 598 -1004 1318 -1194 2145 -40 172 -42 186 -77 435 -19 146 -22 207 -22
525 -1 402 6 482 74 850 32 173 116 469 185 654 229 614 561 1130 1024 1591
785 784 1791 1233 2941 1314 114 8 581 -4 717 -18z"/>
<path d="M5075 9599 c-883 -78 -1639 -360 -2309 -861 -127 -96 -142 -107 -246
-196 -82 -71 -365 -354 -450 -451 -389 -443 -706 -1030 -873 -1616 -48 -169
-54 -194 -83 -340 -133 -690 -96 -1408 107 -2060 203 -654 534 -1215 1005
-1704 717 -744 1620 -1192 2664 -1323 146 -18 229 -21 505 -22 310 -1 445 6
620 34 33 5 105 16 160 25 136 22 400 86 575 141 489 152 999 424 1405 748
1104 881 1701 2209 1616 3596 -63 1028 -485 1977 -1208 2716 -148 152 -233
229 -359 330 -54 43 -101 81 -104 84 -7 8 -80 62 -115 86 -16 11 -52 36 -80
55 -192 134 -548 330 -740 409 -179 73 -317 124 -450 165 -141 43 -412 110
-515 126 -30 4 -98 15 -150 23 -267 42 -717 58 -975 35z m-592 -1765 c40 -9
94 -28 121 -41 65 -33 156 -120 188 -181 29 -52 54 -129 46 -138 -7 -6 -212
-44 -239 -44 -12 0 -25 15 -37 43 -22 49 -86 110 -139 132 -92 39 -246 25
-332 -30 -61 -38 -114 -107 -141 -181 -22 -60 -25 -86 -25 -194 0 -133 11
-190 52 -274 56 -111 158 -176 291 -184 64 -4 90 0 156 22 43 15 98 38 121 52
38 22 43 30 47 67 3 23 2 61 0 85 l-5 42 -142 0 c-109 0 -144 3 -148 13 -3 8
-4 56 -4 108 l2 94 283 3 282 2 0 -259 0 -260 -62 -44 c-71 -50 -208 -108
-313 -132 -103 -24 -289 -22 -385 5 -228 63 -375 228 -431 480 -17 77 -20 116
-16 205 10 203 64 334 186 455 82 81 152 121 261 151 87 24 284 25 383 3z
m877 -649 l-1 -645 -134 0 -135 0 0 645 0 645 135 0 135 0 0 -645z m926 631
c237 -51 380 -254 400 -569 12 -196 -27 -369 -113 -499 -48 -73 -91 -112 -168
-150 -103 -52 -155 -58 -493 -58 l-312 0 0 645 0 645 313 0 c217 -1 330 -5
373 -14z m874 -631 l0 -645 -130 0 -130 0 0 645 0 645 130 0 130 0 0 -645z
m1290 -1000 l0 -65 -2945 0 -2945 0 0 58 c0 32 3 62 7 65 3 4 1329 7 2945 7
l2938 0 0 -65z m-225 -407 c104 -22 209 -95 234 -160 10 -27 31 -202 31 -259
l0 -29 -174 0 -174 0 -4 94 c-2 58 -9 100 -17 110 -17 20 -51 21 -77 2 -16
-12 -19 -27 -19 -104 0 -111 -2 -108 171 -217 167 -106 262 -198 287 -278 26
-82 36 -204 27 -312 -13 -155 -51 -212 -180 -274 l-75 -36 -150 0 c-134 0
-156 3 -208 23 -103 42 -172 109 -198 191 -6 20 -14 100 -16 179 l-5 142 169
0 170 0 7 -132 c8 -146 17 -168 68 -168 58 0 88 114 57 224 -14 51 -43 77
-208 189 -203 138 -236 178 -267 319 -16 75 -14 169 7 275 19 97 97 179 204
214 83 26 235 30 340 7z m-5841 -37 c87 -23 132 -52 176 -113 48 -66 51 -105
48 -630 l-3 -483 -24 -49 c-28 -57 -86 -98 -164 -117 -36 -9 -147 -13 -357
-13 l-305 -1 -3 719 -2 719 277 -6 c234 -5 290 -9 357 -26z m1023 -61 c7 -47
26 -177 43 -290 18 -113 38 -250 45 -305 8 -55 23 -161 35 -235 11 -74 25
-164 30 -200 5 -36 16 -110 25 -165 8 -55 15 -113 15 -128 l0 -28 -187 3 -188
3 -10 125 -10 125 -66 3 -66 3 -10 -120 c-6 -65 -14 -124 -18 -130 -5 -9 -61
-11 -191 -10 l-184 1 0 28 c0 16 16 144 35 287 20 142 40 292 45 333 22 185
101 766 105 778 4 10 63 12 272 10 l267 -3 13 -85z m913 -55 l0 -145 -110 0
-110 0 -2 -572 -3 -573 -165 -3 c-91 -1 -175 0 -187 3 l-23 5 0 570 0 570
-110 0 -110 0 0 138 c0 76 3 142 7 145 3 4 188 7 410 7 l403 0 0 -145z m654
128 c2 -10 7 -38 11 -63 3 -25 10 -72 15 -105 5 -33 21 -139 35 -235 14 -96
30 -202 35 -235 6 -33 26 -170 45 -305 20 -135 42 -285 50 -334 8 -49 15 -102
15 -118 l0 -28 -177 2 c-98 0 -183 3 -189 5 -7 3 -14 46 -18 106 -11 154 -7
147 -81 147 l-62 0 -7 -61 c-3 -34 -6 -81 -6 -105 0 -23 -4 -54 -9 -67 l-10
-25 -176 0 c-97 0 -182 1 -189 4 -10 4 -9 25 1 102 27 209 64 480 74 547 5 39
23 176 40 305 53 407 59 455 64 468 3 9 66 12 269 12 244 0 265 -1 270 -17z
m1321 7 c50 -6 111 -18 138 -27 54 -19 118 -77 146 -131 27 -53 45 -179 39
-275 -8 -122 -39 -171 -123 -198 -58 -18 -60 -23 -11 -38 63 -18 121 -71 141
-128 22 -66 31 -274 16 -388 -8 -61 -20 -104 -37 -133 -55 -93 -97 -103 -501
-108 l-323 -4 0 720 0 720 213 0 c116 0 253 -5 302 -10z m1146 -115 c9 -66 24
-167 33 -225 9 -58 30 -199 47 -315 17 -115 37 -253 45 -305 46 -310 57 -389
58 -425 l1 -40 -182 0 c-100 0 -184 3 -187 6 -3 3 -8 45 -11 95 -3 49 -8 104
-11 122 l-5 32 -64 0 -63 0 -7 -77 c-3 -43 -9 -100 -13 -128 l-7 -50 -184 -3
c-101 -2 -187 1 -191 5 -4 4 14 170 41 368 27 198 53 394 58 435 6 41 22 161
35 265 14 105 31 229 37 278 l12 87 270 -2 271 -3 17 -120z m1809 -20 l0 -145
-125 0 -126 0 3 -132 3 -133 118 -3 118 -3 -3 -137 -3 -137 -118 -3 -118 -3 3
-152 3 -152 138 -3 137 -3 0 -138 c0 -108 -3 -140 -14 -144 -7 -3 -153 -6
-325 -7 l-311 -1 0 721 0 720 310 0 310 0 0 -145z m-3572 -702 l3 -123 -241 0
-240 0 0 118 c0 65 3 122 7 126 4 3 110 5 237 4 l231 -3 3 -122z m-1610 -1091
l3 -42 -50 0 -51 0 0 38 c0 49 5 53 54 49 40 -4 41 -5 44 -45z m410 -194 l2
-237 -34 -7 c-38 -7 -56 0 -56 21 0 12 -7 10 -32 -5 -67 -40 -158 -16 -195 52
-12 23 -18 55 -18 108 0 53 6 85 18 108 35 64 117 90 181 57 15 -8 32 -15 36
-15 4 0 7 30 5 68 -1 37 -1 72 1 77 3 6 23 10 47 10 l42 0 3 -237z m182 196
l0 -44 -45 0 -45 0 0 43 0 43 45 1 45 2 0 -45z m408 -198 l-3 -240 -38 -4
c-32 -3 -39 0 -44 17 -5 19 -5 19 -36 0 -67 -40 -158 -13 -193 59 -27 52 -26
154 2 206 36 70 114 94 187 57 l37 -19 0 78 c0 85 3 89 58 87 l32 -1 -2 -240z
m1142 157 l5 -83 30 20 c62 41 153 15 189 -55 28 -53 29 -155 2 -207 -35 -72
-126 -99 -193 -59 -29 19 -31 19 -36 2 -3 -14 -13 -18 -43 -16 l-39 3 -3 241
-2 242 42 -3 43 -3 5 -82z m-580 7 l0 -60 30 0 c28 0 30 -3 30 -35 0 -32 -2
-35 -30 -35 l-30 0 0 -100 0 -100 29 0 c24 0 29 -5 35 -32 8 -39 -4 -48 -62
-48 -85 0 -102 30 -102 182 0 89 -2 99 -17 96 -14 -2 -19 5 -21 35 -3 31 0 37
17 37 15 0 20 8 23 36 2 27 10 39 33 52 17 9 32 20 33 25 2 4 10 7 18 7 10 0
14 -15 14 -60z m-1855 -80 l25 -23 0 21 c0 19 5 22 46 22 l46 0 -3 -198 c-4
-219 -9 -236 -72 -268 -45 -24 -164 -23 -205 2 -30 17 -52 51 -52 79 0 24 86
20 107 -5 12 -14 30 -20 60 -20 49 0 63 17 63 75 l0 36 -26 -20 c-44 -34 -130
-27 -172 15 -44 45 -57 184 -22 242 11 18 34 41 52 53 44 27 118 22 153 -11z
m1609 16 c19 -8 37 -20 42 -27 5 -8 12 -75 15 -149 3 -74 8 -141 10 -149 6
-22 -70 -23 -84 -1 -10 17 -12 17 -41 -1 -47 -29 -117 -25 -155 7 -27 22 -31
32 -31 74 0 41 4 52 30 73 17 15 54 30 87 37 83 16 93 20 93 33 0 22 -16 37
-47 43 -24 5 -36 1 -52 -15 -22 -22 -66 -27 -91 -11 -13 8 -13 13 0 37 31 57
145 82 224 49z m591 0 c18 -8 38 -23 43 -33 5 -10 12 -74 15 -143 4 -69 8
-134 9 -144 3 -16 -3 -19 -34 -19 -20 0 -44 5 -52 12 -12 10 -21 9 -45 -3 -16
-9 -51 -16 -76 -16 -64 0 -105 39 -105 100 0 67 26 85 168 120 32 8 42 16 42
31 0 37 -93 49 -107 13 -8 -22 -93 -14 -93 8 0 23 43 68 73 77 49 14 124 13
162 -3z m766 4 c59 -16 63 -28 70 -179 3 -75 7 -142 10 -150 3 -10 -6 -15 -31
-16 -44 -2 -60 3 -60 21 0 10 -8 9 -32 -6 -21 -12 -51 -20 -80 -20 -132 0
-156 167 -29 200 20 5 57 14 84 21 26 6 47 17 47 23 0 21 -16 36 -47 42 -24 5
-36 1 -52 -15 -22 -22 -66 -27 -91 -11 -13 8 -13 13 0 37 30 55 122 78 211 53z
m374 -4 c37 -16 73 -62 59 -75 -5 -5 -25 -11 -44 -15 -28 -5 -39 -2 -53 14
-25 30 -107 25 -107 -6 0 -8 23 -20 58 -29 138 -39 162 -56 162 -117 0 -78
-55 -118 -164 -118 -68 0 -113 21 -142 65 -20 31 -13 40 37 49 32 6 39 3 53
-19 13 -20 24 -25 60 -25 27 0 48 6 56 15 19 23 3 34 -75 56 -114 31 -147 66
-129 136 13 54 60 83 136 83 32 0 74 -6 93 -14z m384 -14 c38 -28 61 -73 68
-131 l6 -51 -117 0 c-128 0 -140 -7 -91 -55 29 -30 70 -32 87 -5 14 21 70 28
97 11 12 -8 12 -13 -3 -36 -31 -48 -74 -66 -143 -63 -107 6 -158 65 -157 179
0 78 26 129 82 159 46 25 133 21 171 -8z m560 10 c19 -10 43 -34 53 -52 20
-38 16 -43 -39 -53 -26 -5 -34 -2 -47 18 -12 18 -25 25 -50 25 -51 0 -71 -29
-70 -101 1 -72 21 -99 71 -99 27 0 37 6 51 31 14 25 21 29 46 25 16 -4 35 -6
42 -6 20 0 17 -30 -6 -68 -28 -45 -79 -65 -152 -60 -101 7 -148 64 -148 178 0
81 22 126 76 157 49 28 126 30 173 5z m381 -4 c138 -70 113 -306 -35 -334
-130 -24 -225 47 -225 168 0 60 22 121 54 147 24 20 87 40 126 41 20 0 56 -10
80 -22z m410 7 c17 -9 30 -20 30 -26 0 -6 14 0 31 12 41 29 111 37 144 15 47
-31 56 -64 53 -209 l-3 -132 -32 1 c-18 1 -40 2 -48 3 -12 1 -15 22 -15 124 0
67 -4 127 -8 133 -14 23 -49 25 -73 5 -22 -18 -24 -28 -29 -143 -5 -123 -5
-123 -30 -125 -65 -6 -65 -6 -65 132 0 114 -2 128 -19 141 -11 8 -29 11 -40 8
-45 -13 -50 -29 -56 -156 l-5 -122 -40 0 -40 0 -3 172 -2 172 40 0 c29 0 40
-4 40 -15 0 -19 3 -19 42 5 39 24 88 26 128 5z m-6922 -95 c14 -52 27 -97 29
-99 7 -9 14 9 37 100 l23 94 49 0 48 0 26 -104 c27 -105 31 -111 44 -56 4 17
16 60 28 98 l20 67 44 0 c29 0 44 -4 43 -12 0 -7 -24 -85 -52 -173 l-53 -160
-44 0 -45 0 -23 85 c-36 134 -36 133 -61 34 -12 -49 -25 -97 -28 -106 -3 -14
-15 -18 -48 -18 -42 0 -44 1 -59 43 -13 35 -76 232 -93 290 -5 16 0 18 42 15
l48 -3 25 -95z m506 48 c8 -29 20 -74 27 -100 6 -27 15 -48 19 -48 4 0 18 44
31 98 l25 97 48 0 49 0 22 -85 c12 -47 24 -92 27 -100 7 -18 7 -18 43 103 l26
87 93 0 94 0 27 -100 c15 -55 31 -100 35 -100 3 0 17 45 30 100 l24 100 47 0
46 0 23 -82 c12 -46 25 -94 29 -107 7 -23 9 -18 60 159 7 21 14 25 55 28 56 4
57 21 -15 -197 l-48 -146 -41 0 c-23 0 -45 5 -50 10 -4 6 -18 52 -30 103 -13
51 -25 90 -27 88 -2 -2 -17 -48 -31 -102 l-28 -99 -44 0 -45 0 -28 85 c-16 47
-37 116 -48 154 -11 39 -24 77 -29 85 -6 11 -24 -36 -62 -153 -29 -93 -59
-171 -65 -173 -7 -3 -29 -2 -47 1 -37 7 -34 -1 -78 161 l-14 50 -29 -105 -30
-105 -44 0 -45 0 -53 165 c-29 91 -53 168 -53 173 0 4 20 7 44 7 l45 0 15 -52z
m1634 -120 l-3 -173 -32 1 c-18 1 -40 2 -48 3 -13 1 -15 27 -15 171 l0 170 50
0 50 0 -2 -172z m590 0 l-3 -172 -40 0 -40 0 -3 172 -2 172 45 0 45 0 -2 -172z
m-1180 -122 c4 -45 -4 -52 -55 -51 l-38 0 -3 48 -3 48 48 -3 c48 -3 48 -3 51
-42z m4250 -3 l-3 -48 -40 0 -40 1 -3 47 -3 47 46 0 46 0 -3 -47z m-2158 -353
c0 -6 -6 -19 -12 -30 -11 -17 -10 -22 1 -29 19 -12 -25 -71 -54 -71 -26 0 -48
-25 -70 -83 -26 -69 -5 -86 54 -43 18 14 42 43 53 65 23 47 73 93 97 89 29 -6
23 -37 -18 -81 -48 -52 -54 -87 -16 -87 37 0 70 25 100 75 26 45 59 75 82 75
23 0 14 -31 -22 -73 -52 -60 -33 -58 32 5 32 30 70 62 85 72 57 37 66 -19 13
-85 -52 -65 -45 -88 16 -52 16 10 32 26 35 36 8 27 81 95 125 117 50 26 79 26
79 0 0 -13 -15 -27 -48 -44 -79 -40 -147 -136 -97 -136 15 0 88 67 132 123 26
31 53 36 53 8 0 -10 -11 -44 -25 -75 -25 -58 -22 -71 10 -36 21 23 35 26 35 8
-1 -7 -24 -31 -52 -53 -28 -22 -62 -57 -75 -79 -34 -53 -103 -126 -119 -126
-39 0 14 103 77 149 25 19 69 87 69 107 0 4 -12 -4 -27 -18 -72 -66 -109 -74
-126 -28 -6 17 -15 30 -20 30 -5 0 -27 -16 -50 -35 -48 -42 -74 -45 -83 -10
-4 17 2 39 20 70 37 63 17 56 -46 -15 -29 -33 -58 -60 -64 -60 -7 0 -16 11
-21 25 l-8 26 -35 -31 c-48 -43 -79 -49 -102 -20 -10 12 -18 31 -18 42 0 18
-5 16 -39 -15 -53 -48 -78 -60 -108 -52 -24 6 -25 8 -19 58 5 39 4 48 -5 34
-21 -35 -110 -97 -139 -97 -32 0 -70 39 -70 72 0 17 -7 13 -46 -21 -49 -43
-74 -52 -116 -38 -18 6 -29 18 -33 38 -4 16 -9 29 -12 29 -2 0 -24 -16 -48
-36 -24 -20 -52 -34 -62 -32 -27 5 -28 52 -3 94 12 19 19 38 15 42 -7 7 -104
-96 -105 -110 0 -17 -30 -6 -41 15 -6 12 -13 24 -15 26 -2 2 -22 -13 -44 -33
-47 -43 -77 -47 -86 -11 -4 17 2 39 20 70 37 63 17 56 -45 -15 -29 -33 -58
-60 -65 -60 -8 0 -17 12 -20 26 -6 22 -11 25 -33 19 -14 -3 -45 -19 -68 -36
-23 -16 -48 -29 -57 -29 -17 0 -47 27 -64 56 -9 16 -13 15 -47 -15 -21 -18
-48 -37 -60 -43 -54 -25 -100 18 -100 94 0 37 6 46 63 99 47 45 70 59 92 59
26 0 30 -4 30 -27 0 -36 -46 -94 -58 -74 -19 27 -86 -32 -80 -71 7 -54 147
-12 163 49 13 54 113 131 153 119 9 -3 23 -19 30 -36 12 -26 12 -37 -1 -75
-23 -67 5 -56 58 23 30 45 59 67 75 57 17 -10 11 -25 -25 -68 -48 -56 -27 -59
24 -4 51 55 116 101 130 92 21 -12 11 -53 -24 -96 -39 -49 -44 -69 -17 -69 24
1 53 27 87 82 33 53 69 78 86 61 9 -9 1 -24 -26 -58 -47 -57 -36 -74 13 -20
55 59 120 108 137 101 25 -9 17 -46 -20 -89 -56 -65 -41 -107 18 -49 16 15 38
43 50 62 33 51 94 90 137 87 72 -5 40 -76 -55 -123 -61 -29 -59 -27 -45 -55
22 -41 95 -8 151 68 50 67 114 115 149 111 35 -4 37 -38 4 -77 -21 -25 -29
-29 -40 -20 -11 9 -22 6 -49 -15 -35 -27 -40 -40 -29 -70 10 -25 69 -20 113 9
21 14 57 49 81 78 l42 52 -26 3 c-30 4 -32 13 -10 47 10 15 30 27 52 31 21 4
49 19 65 35 27 29 57 38 57 19z m138 -30 c4 -24 -20 -50 -45 -50 -18 0 -16 45
3 65 21 21 38 15 42 -15z m1442 -71 c0 -12 -14 -42 -31 -67 -60 -89 -57 -121
6 -82 48 30 46 5 -3 -36 -61 -51 -82 -59 -100 -40 -14 14 -14 21 -4 48 17 45
9 45 -28 -2 -20 -25 -40 -40 -54 -40 -26 0 -46 18 -46 41 0 16 -3 16 -42 1
-24 -8 -54 -26 -69 -39 -14 -13 -32 -23 -41 -23 -21 0 -68 38 -68 56 0 33 -25
10 -133 -126 -33 -41 -63 -76 -68 -78 -4 -2 -11 8 -14 21 -6 23 26 95 71 162
26 36 14 37 -28 1 -23 -20 -45 -36 -50 -36 -11 0 -36 40 -43 72 -12 48 83 184
123 176 34 -6 23 -42 -28 -95 -44 -46 -63 -83 -44 -83 16 0 107 76 144 120 23
28 46 50 51 50 24 0 4 -62 -46 -140 -29 -46 -52 -87 -49 -89 6 -6 114 100 114
112 0 5 12 26 28 48 31 44 101 84 131 74 26 -8 38 -66 24 -117 -6 -21 -8 -38
-6 -38 14 0 43 23 43 34 0 7 16 34 35 59 37 48 78 68 98 48 8 -8 -2 -25 -41
-64 -49 -50 -66 -87 -40 -87 7 0 50 40 97 90 88 92 111 106 111 69z m-3734
-173 c7 -17 -25 -65 -43 -66 -19 0 -16 40 4 62 19 22 32 23 39 4z m232 -8 c3
-16 -34 -58 -50 -58 -14 0 -9 34 8 58 17 25 37 25 42 0z m232 -1 c0 -27 -33
-62 -49 -52 -17 10 -13 29 9 53 27 28 40 28 40 -1z"/>
<path d="M5865 7599 c-3 -8 -4 -199 -3 -424 l3 -410 176 0 c163 0 179 2 225
23 57 27 94 74 121 152 15 46 18 87 18 240 0 205 -6 240 -58 317 -24 36 -47
56 -92 78 -58 29 -65 30 -222 34 -130 2 -164 0 -168 -10z"/>
<path d="M2127 5513 c-11 -10 -8 -921 2 -927 19 -12 73 8 89 32 15 23 17 70
20 407 2 209 0 399 -3 423 -6 38 -11 46 -40 58 -38 15 -58 18 -68 7z"/>
<path d="M3122 5315 c-19 -156 -52 -439 -52 -452 0 -9 19 -13 61 -13 l62 0 -7
58 c-3 31 -13 136 -22 232 -8 96 -18 198 -22 225 -7 49 -8 49 -20 -50z"/>
<path d="M4702 5315 c-41 -331 -52 -422 -52 -442 0 -21 4 -23 61 -23 l62 0 -6
58 c-4 31 -14 134 -22 227 -9 94 -19 195 -23 225 l-7 55 -13 -100z"/>
<path d="M6155 5508 c-3 -8 -4 -79 -3 -158 l3 -145 40 3 c68 5 77 26 73 159
-3 126 -9 137 -70 148 -28 5 -39 4 -43 -7z"/>
<path d="M6155 4968 c-3 -7 -4 -97 -3 -198 l3 -185 40 3 c66 5 70 13 73 185 3
136 1 155 -15 175 -23 26 -90 41 -98 20z"/>
<path d="M7151 5320 c-19 -141 -51 -422 -51 -447 0 -21 4 -23 60 -23 34 0 60
4 60 10 0 11 -48 499 -53 535 -1 11 -8 -23 -16 -75z"/>
<path d="M4283 3670 c-31 -13 -43 -41 -43 -105 0 -46 4 -60 25 -80 13 -14 32
-25 42 -25 55 0 93 77 73 149 -15 55 -52 79 -97 61z"/>
<path d="M4872 3670 c-38 -16 -56 -103 -32 -165 11 -28 53 -49 82 -39 27 8 58
63 58 102 0 40 -23 88 -49 102 -23 12 -28 12 -59 0z"/>
<path d="M6258 3673 c-20 -5 -48 -62 -48 -97 0 -41 26 -95 52 -107 54 -24 98
21 98 99 0 57 -9 77 -40 95 -29 17 -33 18 -62 10z"/>
<path d="M3690 3667 c-56 -28 -59 -161 -4 -186 37 -17 52 -14 79 15 20 22 25
38 25 79 0 79 -45 120 -100 92z"/>
<path d="M5315 3553 c-41 -9 -74 -25 -79 -40 -10 -25 12 -53 42 -53 44 0 72
26 72 66 0 19 -3 33 -7 33 -5 -1 -17 -3 -28 -6z"/>
<path d="M5888 3548 c-50 -13 -73 -40 -58 -67 13 -25 56 -28 88 -5 15 11 22
25 22 50 0 19 -3 34 -7 33 -5 -1 -25 -5 -45 -11z"/>
<path d="M6655 3542 c-52 -18 -67 -40 -45 -67 16 -20 60 -19 88 1 15 11 22 25
22 50 0 39 1 39 -65 16z"/>
<path d="M7382 3670 c-18 -8 -42 -45 -42 -66 0 -2 29 -4 65 -4 55 0 65 3 65
18 -1 41 -47 68 -88 52z"/>
<path d="M8307 3656 c-43 -33 -41 -152 3 -176 35 -19 88 -12 104 13 50 79 17
177 -58 177 -17 0 -39 -7 -49 -14z"/>
<path d="M5041 2929 c-31 -30 -39 -49 -22 -49 13 0 60 40 71 61 17 31 -14 24
-49 -12z"/>
<path d="M4303 2898 c-37 -40 -51 -86 -28 -95 25 -9 65 17 63 41 -1 12 2 36 6
54 4 17 4 32 -1 32 -5 0 -23 -15 -40 -32z"/>
<path d="M6747 2896 c-25 -23 -37 -42 -37 -60 0 -58 64 -39 73 21 3 21 8 46
12 56 11 27 -7 20 -48 -17z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
